@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@100;200;300;400;600;700;800&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

body {
  font-size: 1.2rem;
  line-height: 1.3;
  font-family: 'Poppins', sans-serif;
}


#imga{
  left: 0;
  right: 0;
 height: 240px;
 width: 250px;
}

.card{
  list-style: none;
}

