.navbar {
    width: 100%;
    height: 84px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #000000;
    position: fixed;

  }
  
  .menu {
    display: flex;
    align-items: center;
  }
  
  .menu li {
    padding: 1rem;
  }
  
  .navbar a {
    color: white;
    font-size: 0.8rem;
  }
  
  .mobile_btn {
    display: none;

  }
  
  @media screen and (max-width: 720px) {
    .mobile_btn {
      display: block;
      position: absolute;
      right: 1rem;
      cursor: pointer;
      z-index: 10;
      color: white;
    }
  
    .menu {
      display: flex;
      position: fixed;
      border: 1px solid white;
      flex-direction: column;
      justify-content: center;
      align-items: center; /* Center the menu horizontally */
      font-family: "Fjalla One", sans-serif;
      top: 50%; /* Start from the vertical center */
      left: 50%; /* Start from the horizontal center */
      transform: translate(-50%, -50%); /* Center the menu */
      width: 80%; /* Adjust the width of the menu */
      max-width: 300px; /* Maximum width of the menu */
      height: auto; /* Allow the menu to expand vertically */
background-color: #000000;
      border-radius: 5px; /* Rounded corners */
      z-index: 10;
      opacity: 0; /* Initially hidden */
      transition: opacity 0.5s;
    }
    
    .menu.active {
      
      opacity: 1;
    }
    
   
  
   
    .navbar a {
      font-size: 2rem;
    }
  }


  .navbar2 {
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: black;
  }
  .navbar2 a {
    color: white;
    font-size: 0.8rem;
  }


  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap')