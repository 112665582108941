.banner{
  background: url('banners/asia.jpg')
          no-repeat center/cover;
        width: 100%;
        height: 500px;
     
        display: cover;
     
    
}

.bant{
    color: white;
    font-size: 4.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-family: "Forum", serif;
}

@media only screen and (max-width: 600px) {
  .bant{
    color: white;
    font-size: 3.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-family: "Forum", serif;
}

}
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap')