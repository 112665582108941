.first{

font-family: sans-serif;  font-size: 1.4rem;
    text-align: center;
    padding: 2rem;
    color: rgb(236, 241, 228);
    
}
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');



.image{
background-color: #000000;    
    
    max-width: 1650px;
}


.find {
    max-width: 1040px;
    margin: auto;
  }
  
  .heading h1 {
    font-size: 4rem;
    text-align: center;
    padding: 2rem;
  }
  
  .heading p {
    text-align: center;
  }
  
  .text_bg {
    background: rgba(173, 199, 255, 0.502);
    height: 30px;
    max-width: 650px;
    margin: auto;
    position: absolute;
  
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  
  .text_bg p {
    font-size: 1.3rem;
    font-weight: 600;
    margin-top: -0.6rem;
  }
  
  .slider_container {
    padding: 5rem 0;
  }
  
  
  @media only screen and (max-width: 600px) {
    p {
      font-size: 1.2rem; 
      /* Adjust font size for mobile */
    }
  }

  .card {
    margin: .5rem;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  }
  
  .card img {
    width: 100%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 220px;
    object-fit: cover;
  }
  
  .card p {
    text-align: center;
    padding: 0.5rem;
    font-size: 1rem;
  }
  
  
  
  @media screen and (max-width: 500px) {
    .card {
      width: 100%;
    }
  }
  
  .swiper-container {
    width: 480px;
  }
  
  @media screen and (min-width: 640px) {
    .swiper-container {
      width: 640px;
    }
  }
  
  @media screen and (min-width: 768px) {
    .swiper-container {
      width: 768px;
    }
  }


  #ccar{
    max-width: 100%;
    min-width: 640px;
    height: 500px;
  }




  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


  /* App.css */
.section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.section.visible {
  opacity: 1;
  transform: translateY(0);
}

@keyframes fadePop {
  0% { opacity: 0; transform: translateY(20px) scale(0.9); }
  50% { opacity: 1; transform: translateY(0) scale(1.05); }
  100% { opacity: 1; transform: translateY(0) scale(1); }
}

/* Carousel.css */
/* Carousel.css */
.carousel {
  position: relative;
  max-width: 100%;
  height: 520px;
  overflow: hidden;
}

.carousel img {
  display: block;
  width: 100%;
  height: 520px; /* Adjust to fill the entire width of the carousel container */
  
}

.carousel button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: white;
  font-size: 24px;
  padding: 10px;
  cursor: pointer;
  outline: none;
  transition: background 0.3s ease;
}

.carousel button:hover {
  background: rgba(0, 0, 0, 0.7);
}

.carousel button:first-child {
  left: 10px;
}

.carousel button:last-child {
  right: 10px;
}

/* Styling for next button */
.carousel button:last-child {
  background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
}

.carousel button:last-child:hover {
  background: rgba(0, 0, 0, 0.7);
}

/* Slide location indicators */
.slide-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.slide-indicator {
  width: 10px;
  height: 10px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.slide-indicator.active {
  background: white;
}
.atls{
  height: 400px;
  width: 700px;
}
@media only screen and (max-width: 600px) {
  .carousel button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 24px;
    padding: 10px;
    cursor: pointer;
    outline: none;
    transition: background 0.3s ease;
  }
    .carousel {
      position: relative;
      max-width: 100%;
      height: 220px;
      overflow: hidden;
    }
    
    .carousel img {
      display: block;
      width: 100%;
      height: 220px; /* Adjust to fill the entire width of the carousel container */
      
    }
    
  .atls{
    height: 250px;
    width: 100%;
  }

  
}

.botbtn{
  padding-top: 2rem;
  text-align: center;
  font-family: "Barlow", sans-serif;
  font-size: 1.4rem;
color: white;
}
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
