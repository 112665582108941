.afb{
    background: url('banners/asia.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 500px;

  display: cover;


}
.afb2{
    background: url('banners/africa.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 500px;

  display: cover;


}
.afb3{
    background: url('banners/america.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 500px;

  display: cover;
}
.aafb{
    color: white;
    font-size: 4.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-family: "Forum", serif;
}
.afb2{
    color: white;
    font-size: 4.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-family: "Forum", serif;
}
.afb3{
    color: white;
    font-size: 4.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-family: "Forum", serif;
}

.afb4{
    background: url('banners/europe.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 500px;

  display: cover;


}
.afb5{
    background: url('banners/ant.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 500px;

  display: cover;


}
.afb6{
    background: url('banners/australia.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 500px;

  display: cover;

}
.afb4{
    color: white;
    font-size: 4.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-family: "Forum", serif;
}
@media only screen and (max-width: 600px) {
    .aafb{
        color: white;
        font-size: 3.7rem;
        left: 0;
        right: 0;
        text-align: center;
        font-family: "Forum", serif;
    }
    .bannereu{
        color: white;
        font-size: 3.7rem;
        left: 0;
        right: 0;
        text-align: center;
        font-family: "Forum", serif;
    
    }
}

.bannereu{
    color: white;
    font-size: 3.7rem;
    left: 0;
    right: 0;
    text-align: center;
    font-family: "Forum", serif;

}