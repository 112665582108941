
.first{
    background-image: linear-gradient(to bottom, rgb(246, 188, 79),#b7946a);
}

.imga{

    left: 0;
      right: 0;
     height: 250px;
     width: 275px;
     border-radius: 50%;
    }


 .hed{
    text-align: center;
    font-size: 3.0rem;
    font-family: 'Kaushan Script', cursive;
    color: rgb(74, 38, 4);
    transition: bottom 5s;
 }   

 .para{
  font-family: "Hammersmith One", sans-serif;
   color: rgb(74, 38, 4);
    text-align: center;
    font-size: 1.9rem;
    padding: 2.5rem;
 }

 .bd{
    list-style: none;
    padding: 2.7rem;
    text-align: center;
}

.s{
    background: url('images/afr.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 570px;

  display: cover;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');