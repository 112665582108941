.imga{
  left: 0;
  right: 0;
 height: 220px;
 width: 275px;
 border-radius: 50%;
}


.hed{
  text-align: center;
  font-size: 3.0rem;
  font-family: "Agbalumo", system-ui;

  color: #ffffff;
  transition: bottom 5s;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


.s{
  background: url('images/gir.jpg')
  no-repeat center/cover;
width: 100%;
height: 570px;
border-radius: 25px;
display: cover;
}

.s2{
  background: url('images/afr.jpg')
  no-repeat center/cover;
width: 100%;
height: 570px;
border-radius: 25px;
display: cover;
}

.imga{
  left: 0;
  right: 0;
 height: 220px;
 width: 275px;
 border-radius: 50%;
}

.first{
background-color: rgb(0, 0, 0);}
.hed{
  text-align: center;
  font-size: 3.0rem;
 

  color: #ffffff;
  transition: bottom 5s;
}

.para{
  font-family: "Hammersmith One", sans-serif;
  font-size: 3.0rem;
  color: #ffffff;
  text-align: center;
  font-size: 1.7rem;
  padding: 2rem;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');




.cord{
  background-color: rgb(90, 90, 55);
  padding: 0.4rem;
}
.title{
   padding-top: 3rem;
   color: white;
   padding-left:5rem ; 
   font-size: 4rem;
}


@media only screen and (max-width: 600px) {
   .hed{
       text-align: center;
       font-size: 2.0rem;
       font-family: "Agbalumo", system-ui;
   
       
      
   }
   
   .para{
       font-family: "Hammersmith One", sans-serif;
       
       text-align: center;
       font-size: 1.2rem;
       padding: 2rem;

   }
   .imga{
       left: 0;
       right: 0;
      height: 220px;
      width: 240px;
      border-radius: 50%;
   }
}

.bd{
  padding-left: 12.7rem;
  color: rgb(255, 255, 255);
  font-family: "Lato", sans-serif;
  font-size: 2.2rem;
  
}

@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');




.section {
   opacity: 0;
   transform: translateY(20px);
   transition: opacity 0.5s ease, transform 0.5s ease;
 }
 
 .section.visible {
   opacity: 1;
   transform: translateY(0);
 }
 
 @keyframes fadePop {
   0% { opacity: 0; transform: translateY(20px) scale(0.9); }
   50% { opacity: 1; transform: translateY(0) scale(1.05); }
   100% { opacity: 1; transform: translateY(0) scale(1); }
 }
 
 @media only screen and (max-width: 600px) {
 
 .title{
  
  padding-left: 2.5rem;
  
   font-size: 2.5rem;
 }
 .bd{
   padding-left: 3rem;
   font-size: 1rem;
   
 }
 .s{
  
 
 height: 270px;
 
 display: cover;
 }
 .s2{
  height: 270px;
 
  display: cover;
  }
}

