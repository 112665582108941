.quiz-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 600px;
    margin: 0 auto;
    padding: 30px;
    color: white;
 
    font-family: "Hammersmith One", sans-serif;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  h2 {
    margin-top: 0;
    font-family: "Hammersmith One", sans-serif;
  }
  
  h3 {
    margin-top: 20px;
    font-family: "Hammersmith One", sans-serif;
  }
  
  ul {
    list-style-type: none;
    font-family: "Hammersmith One", sans-serif;
    padding: 0;
    margin: 20px 0;
  }
  
  li {
    margin-bottom: 10px;
    font-family: "Hammersmith One", sans-serif;
  }
  
  button {
    padding: 10px 20px;
    background-color: #63b07a;
    color: #ffffff;
    border: none;
    font-family: "Hammersmith One", sans-serif;
    border-radius: 5px;
    border-radius: 15px;
    
    cursor: pointer;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  button:hover {
    background-color: #77b57a;
  }
  button:active{
    background:rgb(98, 182, 143);
}

  
  .correct {
    color: green;
  }
  
  .wrong {
    color: red;
  }
  
  