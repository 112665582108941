

.s{
    background: url('images/kangaroob.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 570px;
  border-radius: 25px;
  display: cover;
  }
  
  .first1{
      background: linear-gradient(to bottom , white, #e9f1f5);
  }
  
  
  
  .para{
    font-family: "Hammersmith One", sans-serif;
      color: #0e0101;
      text-align: center;
      font-size: 1.9rem;
      padding: 2.5rem;
  }
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  
  
  
  .yes{
    background: linear-gradient( to bottom, grey, rgb(224, 198, 151));
  }
  
  .imga{
    left: 0;
    right: 0;
   height: 220px;
   width: 275px;
   border-radius: 50%;
  }
  
  
  .hed{
    text-align: center;
    font-size: 3.0rem;
    font-family: "Agbalumo", system-ui;
  
    color: #ffffff;
    transition: bottom 5s;
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  
  
  
  
  
  
  .s2{
    background: url('images/emub.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 570px;
  border-radius: 25px;
  display: cover;
  }
  
  
  
  .s3{
    background: url('images/casa.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 570px;
  border: 25px;
  display: cover;
  }
  
  .s4{
    background: url('images/koala.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 570px;
  border-radius: 25px;
  display: cover;
  }
  
  .s5{
    background: url('images/ela.jpg')
    no-repeat center/cover;
  width: 100%;
  border-radius: 25px;
  height: 570px;
  
  display: cover;
  }
  
  
  .first{
  background-color: rgb(0, 0, 0);}
  .hed{
    text-align: center;
    font-size: 3.0rem;
   
  
    color: #ffffff;
    transition: bottom 5s;
  }
  
  .para{
    font-family: "Hammersmith One", sans-serif;
    font-size: 3.0rem;
    color: #ffffff;
    text-align: center;
    font-size: 1.7rem;
    padding: 2rem;
  }
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  
  
  
  
  .cord{
    background-color: rgb(92, 60, 5);
    padding: 0.4rem;
  }
  
  
  .cordg{
  background-color: rgb(137, 58, 74);
  padding: 0.4rem;
  }
  .title{
     padding-top: 3rem;
     color: white;
     padding-left:5rem ; 
     font-size: 4rem;
  }
  
  
  @media only screen and (max-width: 600px) {
     .hed{
         text-align: center;
         font-size: 2.0rem;
         font-family: "Agbalumo", system-ui;
     
         
        
     }
     
     .para{
         font-family: "Hammersmith One", sans-serif;
         
         text-align: center;
         font-size: 1.2rem;
         padding: 2rem;
  
     }
     .imga{
         left: 0;
         right: 0;
        height: 220px;
        width: 240px;
        border-radius: 50%;
     }
  }
  
  .bd{
    padding-left: 12.7rem;
    color: rgb(255, 255, 255);
    font-family: "Lato", sans-serif;
    font-size: 2.2rem;
    
  }
  
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  
  
  
  
  .section {
     opacity: 0;
     transform: translateY(20px);
     transition: opacity 0.5s ease, transform 0.5s ease;
   }
   
   .section.visible {
     opacity: 1;
     transform: translateY(0);
   }
   
   @keyframes fadePop {
     0% { opacity: 0; transform: translateY(20px) scale(0.9); }
     50% { opacity: 1; transform: translateY(0) scale(1.05); }
     100% { opacity: 1; transform: translateY(0) scale(1); }
   }
   
   @media only screen and (max-width: 600px) {
   
   .title{
    
    padding-left: 2.5rem;
    
     font-size: 2.5rem;
   }
   .bd{
     padding-left: 3rem;
     font-size: 1rem;
     
   }
   .s{
  
   
   height: 270px;
   
   display: cover;
   }
  
  .s2{
   
    height: 270px;
  display: cover;
  }
  
  .s3{
    height: 270px;
  display: cover;
  }
  
  
  .s4{
    height: 270px;
  
  display: cover;
  }
  
  .s5{
    height: 270px;
  
  
  display: cover;
  }
   }
  
  
  
  
  
  
  
  
  .first2{
      background: linear-gradient(to bottom  , #e9f1f5,rgb(98, 88, 88));
  }
  
  
  





