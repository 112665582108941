.imga{
    left: 0;
    right: 0;
   height: 220px;
   width: 275px;
   border-radius: 50%;
}

.first{
    background-color: rgb(239, 214, 121);
}

.hed{
    text-align: center;
    font-size: 3.0rem;
    font-family: 'Kaushan Script', cursive;

    color: rgb(67, 52, 5);
    transition: bottom 5s;
}

.para{
    font-family: "Hammersmith One", sans-serif;
    color: rgb(67, 52, 5);
    text-align: center;
    font-size: 1.9rem;
    padding: 2.5rem;
}

@import url('https://fonts.googleapis.com/css2?family=Hammersmith+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');



.s{
    background: url('images/tigress.jpg')
    no-repeat center/cover;
  width: 100%;
  border-radius: 25px;
  height: 570px;

  display: cover;
}

.cord{
   background-color: rgb(243, 159, 4);
   padding: 0.4rem;
}
.title{
    padding-top: 3rem;
    color: white;
    padding-left:5rem ; 
    font-size: 4rem;
}


@media only screen and (max-width: 600px) {
    .hed{
        text-align: center;
        font-size: 2.0rem;
        font-family: 'Kaushan Script', cursive;
    
        color: rgb(67, 52, 5);
       
    }
    
    .para{
        font-family: "Hammersmith One", sans-serif;
        color: rgb(67, 52, 5);
        text-align: center;
        font-size: 1.2rem;
        padding: 3.5rem;

    }
    .imga{
        left: 0;
        right: 0;
       height: 220px;
       width: 240px;
       border-radius: 50%;
    }
}

.bd{
   padding-left: 12.7rem;
   color: rgb(255, 223, 162);
   font-family: "Lato", sans-serif;
   font-size: 2.2rem;
   
}

@keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }


  /* App.css */
.section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.section.visible {
  opacity: 1;
  transform: translateY(0);
}

@keyframes fadePop {
  0% { opacity: 0; transform: translateY(20px) scale(0.9); }
  50% { opacity: 1; transform: translateY(0) scale(1.05); }
  100% { opacity: 1; transform: translateY(0) scale(1); }
}

@media only screen and (max-width: 600px) {

.title{
 
  padding-left: 2.5rem;
 
  font-size: 2.5rem;
}
.bd{
  padding-left: 3rem;
  font-size: 1rem;
  
}
.s{
  background: url('images/tigress.jpg')
  no-repeat center/cover;

height: 270px;

display: cover;
}
}
