.first{
    background: linear-gradient(to bottom,#c8ba8f,rgb(242, 240, 233));
}

.imga{
    left: 0;
    right: 0;
   height: 220px;
   width: 275px;
   border-radius: 50%;
}

.first1{
    background: linear-gradient(to bottom , white, #e9f1f5);
}

.hed{
    text-align: center;
    font-size: 3.0rem;
    font-family: 'Kaushan Script', cursive;

    color: #520606;
    transition: bottom 5s;
}

.para{
    font-family: 'Teko', sans-serif;
    color: #520606;
    text-align: center;
    font-size: 1.9rem;
    padding: 2.5rem;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


.s{
    background: url('images/vick.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 570px;

  display: cover;
}



