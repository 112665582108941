.imga{
    left: 0;
    right: 0;
   height: 220px;
   width: 275px;
   border-radius: 50%;
}

.first{
    background: linear-gradient(to bottom right, #1406dc, #f7f2f3);
}

.hed{
    text-align: center;
    font-size: 3.0rem;
    font-family: 'Kaushan Script', cursive;

    color: #eee6e6;
    transition: bottom 5s;
}

.para{
    font-family: "Hammersmith One", sans-serif;
    color: #f1e3e3;
    text-align: center;
    font-size: 1.9rem;
    padding: 2.5rem;
}
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');


.s{
    background: url('images/ox.jpg')
    no-repeat center/cover;
  width: 100%;
  height: 570px;

  display: cover;
}

