.hero {
    background: url('Summer.jpg')
      no-repeat center/cover;
    width: 100%;
    height: 500px;
 
    display: cover;
  }

  .first{
    font-family:  'Dancing Script', cursive; 
    font-size: 4.7rem;
    animation: fadeIn 5s ease-in-out;
    text-align: center;
   left: 0;
   right: 0;

    color: white;
}
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@600&display=swap');


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}